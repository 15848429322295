import React from 'react';
import Link from 'gatsby-link';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { MEDIA_QUERY } from '../styles/variables';

type Props = {
  data: any;
};

const ArticleList = (props: Props): JSX.Element => {
  const { data } = props;

  return (
    <UL_Wrap>
      {data.allContentfulBlogPost.edges.map(({ node }) => (
        <LI_ArticleItem key={node.id}>
          <Link to={`/article/${node.slug}/`}>
            <FIGURE_ArticleImage>
              <Img
                fluid={node.eyecatch.fluid}
                alt={node.eyecatch.description}
                style={{ height: '100%' }}
              />
            </FIGURE_ArticleImage>
            <H3_ArticleTitle>{node.title}</H3_ArticleTitle>
          </Link>
        </LI_ArticleItem>
      ))}
    </UL_Wrap>
  );
};

const UL_Wrap = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -20px;
`;

const LI_ArticleItem = styled.li`
  width: 100%;
  margin-top: 20px;

  @media (min-width: ${MEDIA_QUERY.middle}) {
    width: 48%;
  }

  @media (max-width: ${MEDIA_QUERY.middle}) {
    & + & {
      margin-top: 28px;
    }
  }

  a {
    display: flex;
    align-items: center;
  }
`;

const FIGURE_ArticleImage = styled.figure`
  max-height: 100%;
  width: 40%;
  position: relative;

  @media (min-width: ${MEDIA_QUERY.middle}) {
    width: 50%;
  }

  img {
    position: relative;
    object-fit: cover;
  }
`;

const H3_ArticleTitle = styled.h3`
  flex: 1;
  margin-left: 16px;
  font-size: 1.6rem;

  @media (min-width: ${MEDIA_QUERY.middle}) {
    font-size: 1.8rem;
  }
`;

export { ArticleList };
